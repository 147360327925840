import { Row, Col, Container } from "react-bootstrap";

export const validateGoalCalculator = (form) => {
  let error = "";
  if (form.goalAmount <= 0) {
    error = "Please provide your goal amount";
  } else if (form.duration <= 0 || form.duration >= 70) {
    error = "Please provide valid duration in years";
  } else if (form.rate <= 0) {
    error = "Please provide an expected return rate";
  }
  return error;
};

export const goalSipCalculator = (
  goalAmount,
  rateOfReturn,
  investmentDuration
) => {
  function pmt(rate, per, nper, pv, fv) {
    if (fv === 0) {
      return null;
    }
    rate = rate / (per * 100);
    if (rate === 0) {
      pmtValue = -(fv + pv) / nper;
    } else {
      var x = Math.pow(1 + rate, nper);
      fv = fv + x * pv;
      pmtValue = -((rate * fv) / (-1 + x));
    }
    return pmtValue;
  }

  goalAmount = parseFloat(goalAmount);
  rateOfReturn = parseFloat(rateOfReturn);
  investmentDuration = parseFloat(investmentDuration);

  var per = 12;
  var nper = per * investmentDuration;

  var pmtValue = pmt(rateOfReturn, per, nper, 0, goalAmount) * -1;
  var investment = pmtValue * nper;

  return {
    pmtValue: pmtValue,
    investment: investment,
    earnings: goalAmount - investment,
  };
};

export const validateSIPCalculator = (form) => {
  let error = "";
  if (form.sipAmount <= 0) {
    error = "Please provide your SIP amount";
  } else if (form.duration <= 0 || form.duration >= 70) {
    error = "Please provide valid duration in years";
  } else if (form.rate <= 0) {
    error = "Please provide an expected return rate";
  }
  return error;
};

export const sipCalculator = (
  sipAmount,
  per,
  rateOfReturn,
  investmentDuration
) => {
  function fv(rate, per, nper, pmt, pv) {
    if (pmt === 0 || nper === 0 || per === 0) {
      return null;
    }
    rate = rate / (per * 100);
    var futureValue;
    if (rate === 0) {
      futureValue = -(pv + pmt * nper);
    } else {
      var x = (Math.pow(1 + rate, nper) - 1) / rate;
      futureValue = -pmt * x * (1 + rate);
    }
    return futureValue;
  }

  sipAmount = parseFloat(sipAmount);
  rateOfReturn = parseFloat(rateOfReturn);
  investmentDuration = parseFloat(investmentDuration);

  var nper = per * investmentDuration;
  var futureValue = fv(rateOfReturn, per, nper, sipAmount * -1, 0);
  var investment = sipAmount * nper;

  return {
    futureValue: futureValue,
    investment: investment,
    earnings: futureValue - investment,
  };
};

export const getSIPPeriod = (frequency) => {
  let per = 0;
  switch (frequency) {
    case "monthly":
      per = 12;
      break;
    case "quarterly":
      per = 4;
      break;
    case "half-yearly":
      per = 2;
      break;
    case "yearly":
      per = 1;
      break;
    default:
      per = 0;
  }
  return per;
};

export const inflationCalculator = (
  currentValue,
  annualInflationRate,
  period
) => {
  currentValue = parseFloat(currentValue);
  annualInflationRate = parseFloat(annualInflationRate);
  period = parseFloat(period);
  var inflatedValue =
    currentValue * Math.pow(1 + annualInflationRate / 100, period);
  return inflatedValue;
};

export const validateInflationCalculator = (form) => {
  let error = "";
  if (form.currentValue <= 0) {
    error = "Please provide current value";
  } else if (form.duration <= 0 || form.duration >= 70) {
    error = "Please provide valid duration in years";
  } else if (form.rate <= 0 || form.rate >= 15) {
    error = "Please provide valid time period";
  }
  return error;
};

export const validateSWPCalculator = (form) => {
  let error = "";
  if (form.investment <= 0) {
    error = "Please provide your total investment";
  } else if (form.duration <= 0 || form.duration >= 70) {
    error = "Please provide valid duration in years";
  } else if (form.rate <= 0) {
    error = "Please provide an expected return rate";
  }
  return error;
};

export const swpCalculator = (
  investment,
  withdrawalAmount,
  rateOfReturn,
  investmentDuration
) => {
  var finalAmount = Math.round(
    investment * Math.pow(1 + rateOfReturn / 100, investmentDuration) -
      (withdrawalAmount *
        (Math.pow(
          1 + (Math.pow(1 + rateOfReturn / 100, 1 / 12) - 1),
          investmentDuration * 12
        ) -
          1)) /
        (Math.pow(1 + rateOfReturn / 100, 1 / 12) - 1)
  );

  return {
    futureValue: finalAmount,
    totalWithdrawal: withdrawalAmount * investmentDuration * 12,
  };
};

export const getMutualFundCalculators = () => {
  return (
    <Container className="calculators">
      <Row>
        <Col sm={6} md={4}>
          <a className="tool-box" href="/goal-calculator">
            <div className="tool">
              <h4>Goal Calculator</h4>
              Determine the monthly SIP investments you need to make to reach a
              particular goal.
            </div>
          </a>
        </Col>
        <Col sm={6} md={4}>
          <a className="tool-box" href="/sip-calculator">
            <div className="tool">
              <h4>SIP Calculator</h4>
              Find the future value of your monthly/quarterly SIP investments
              through mutual funds.
            </div>
          </a>
        </Col>
        <Col sm={6} md={4}>
          <a className="tool-box" href="/swp-calculator">
            <div className="tool">
              <h4>SWP Calculator</h4>
              SWP calculator shows the total value of the mutual fund investment
              after the withdrawal
            </div>
          </a>
        </Col>
        <Col sm={6} md={4}>
          <a className="tool-box" href="/inflation-calculator">
            <div className="tool">
              <h4>Inflation Calculator</h4>
              How much you will need in the future to meet your current expenses
              against inflation.
            </div>
          </a>
        </Col>  
      </Row>
    </Container>
  );
};
