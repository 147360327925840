import { Navbar, Nav, Container } from "react-bootstrap";
import { Helmet } from "react-helmet";

function Header(props) {
  function getMetaHeader() {
    console.log("Page prop: ", props.page);
    switch (props.page) {
      case "about":
        return (
          <Helmet>
            <title>
              About Kashly - Personalised Mutual Fund Platform in India
            </title>
            <meta
              property="og:description"
              name="description"
              content="Kashly - A hybrid platform, which help investors to take informed financial decisions and invest in mutual funds with the help of a mutual fund advisory."
            />
            <link rel="canonical" href="https://kashly.in/about" />
          </Helmet>
        );

      case "contact":
        return (
          <Helmet>
            <title>Contact Kashly - Talk to our mutual fund experts</title>
            <meta
              property="og:description"
              name="description"
              content="Kashly - A hybrid platform, which help investors to take informed financial decisions and invest in mutual funds with the help of a mutual fund advisory."
            />
            <link rel="canonical" href="https://kashly.in/contact" />
          </Helmet>
        );
      case "signup":
        return (
          <Helmet>
            <title>Join Kashly - Start mutual fund investment in India</title>
            <meta
              property="og:description"
              name="description"
              content="Kashly - A hybrid platform, which help investors to take informed financial decisions and invest in mutual funds with the help of a mutual fund advisory."
            />
            <link rel="canonical" href="https://kashly.in/open-account" />
          </Helmet>
        );
      case "funds":
        return (
          <Helmet>
            <title>
              Explore Mutual Funds - The vast opportunities for investments
            </title>
            <meta
              property="og:description"
              name="description"
              content="Search and invest in right mutual funds through Kashly."
            />
            <link rel="canonical" href="https://kashly.in/funds" />
          </Helmet>
        );
      case "calculators":
        return (
          <Helmet>
            <title>Kashly - Mutual Fund Calculators</title>
            <meta
              property="og:description"
              name="description"
              content="Plan your investments with our SIP Calculator, Lumpsum Calculator, Goal Calculator, Inflation Calculator and Risk Calculator."
            />
            <link rel="canonical" href="https://kashly.in/calculators" />
          </Helmet>
        );
      case "disclosure":
        return (
          <Helmet>
            <title>Kashly - Disclosure</title>
            <meta
              property="og:description"
              name="description"
              content="Kahsly Commission Disclosure"
            />
            <link rel="canonical" href="https://kashly.in/disclosure" />
          </Helmet>
        );
      case "nriaccount":
        return (
          <Helmet>
            <title>Kashly - NRI Account Opening</title>
            <meta
              property="og:description"
              name="description"
              content="Kahsly - NRI Account Opening"
            />
            <link rel="canonical" href="https://kashly.in/nri-account" />
          </Helmet>
        );
      case "sipcalculator":
        return (
          <Helmet>
            <title>Kashly - SIP Calculator</title>
            <meta
              property="og:description"
              name="description"
              content="Plan your investments with our SIP Calculator."
            />
            <link rel="canonical" href="https://kashly.in/sip-calculator" />
          </Helmet>
        );
      case "swpcalculator":
        return (
          <Helmet>
            <title>Kashly - SWP Calculator</title>
            <meta
              property="og:description"
              name="description"
              content="Plan your investments with our SWP Calculator."
            />
            <link rel="canonical" href="https://kashly.in/swp-calculator" />
          </Helmet>
        );
      case "goalcalculator":
        return (
          <Helmet>
            <title>Kashly - Goal Calculator</title>
            <meta
              property="og:description"
              name="description"
              content="Plan your investments with our Goal Calculator."
            />
            <link rel="canonical" href="https://kashly.in/goal-calculator" />
          </Helmet>
        );
      case "inflationcalculator":
        return (
          <Helmet>
            <title>Kashly - Inflation Calculator</title>
            <meta
              property="og:description"
              name="description"
              content="Plan your investments with our Inflation Calculator."
            />
            <link
              rel="canonical"
              href="https://kashly.in/inflation-calculator"
            />
          </Helmet>
        );
      case "nfo":
        return (
          <Helmet>
            <title>New Fund Offerings</title>
            <meta
              property="og:description"
              name="description"
              content="New Fund Offerings Mutual Funds"
            />
            <link rel="canonical" href="https://kashly.in/nfo" />
          </Helmet>
        );
      case "terms":
        return (
          <Helmet>
            <title>Kashly - Terms and Conditions</title>
            <meta
              property="og:description"
              name="description"
              content="Kashly Terms and Conditions"
            />
            <link rel="canonical" href="https://kashly.in/terms" />
          </Helmet>
        );
      case "privacy":
        return (
          <Helmet>
            <title>Kashly - Privacy Policy</title>
            <meta
              property="og:description"
              name="description"
              content="Kashly Privacy Policy"
            />
            <link rel="canonical" href="https://kashly.in/privacy" />
          </Helmet>
        );
      case "refund":
        return (
          <Helmet>
            <title>Kashly - Refund Policy</title>
            <meta
              property="og:description"
              name="description"
              content="Kashly Refund Policy"
            />
            <link rel="canonical" href="https://kashly.in/privacy" />
          </Helmet>
        );

      default:
        return (
          <Helmet>
            <title>Kashly - Personalised Mutual Fund Investment Platform in India</title>
            <meta
              property="og:description"
              name="description"
              content="Kashly - A hybrid platform, which help investors to take informed financial decisions and invest in mutual funds with the help of a mutual fund advisory."
            />
            <link rel="canonical" href="https://kashly.in" />
          </Helmet>
        );
    }
  }

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      fixed="top"
      bg="white"
      className="navigation border-bottom border-medium"
    >
      {getMetaHeader()}
      <Container>
        <Navbar.Brand href="/">
          <img
            className="kashly-logo"
            alt="kashly-logo"
            src="/assets/images/kashly-logo.svg"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link href="https://app.kashly.in" target="_blank">
              Login
            </Nav.Link>
            <Nav.Link href="/funds">Explore</Nav.Link>
            <Nav.Link href="https://kashly.in/read" target="_blank">
              Blog
            </Nav.Link>
            <Nav.Link href="/contact">Contact</Nav.Link>
            <Nav.Link href="/open-account">Signup</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
export default Header;
